<template>
    <div
        v-if="!!state.found"
        :class="{ active: show }"
        class="popover popover-bottom hover-disabled cheatsheet"
    >
        <default-button
            flavor="link"
            :title="translate('Cheat Sheet')"
            @click.prevent.stop="toggle"
        >
            <music-2-icon size="lg" />
        </default-button>
        <div class="popover-container">
            <table class="table table-striped">
                <tbody>
                    <tr
                        v-for="song in filtered_rows"
                        :key="song.id"
                    >
                        <td>
                            {{ song.title }}
                            <strong class="text-italic">{{ song.starting_key }}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { is_remote_data_table_source } from "@/nibnut/mixins"

import {
    DefaultButton
} from "@/nibnut/components"
import { Music2Icon } from "@/nibnut/icons"

export default {
    name: "Cheatsheet",
    mixins: [is_remote_data_table_source],
    components: {
        DefaultButton,
        Music2Icon
    },
    methods: {
        filter_rows (rows) {
            return rows
        },
        auto_close (event) {
            // if(!!event && !!event.target && !this.$el.contains(event.target)) {
            this.toggle()
        },
        toggle (event) {
            if(!!event && !!event.target) event.target.closest("button").blur()
            if(this.show) {
                this.show = false
                document.removeEventListener("click", this.auto_close)
            } else {
                document.addEventListener("click", this.auto_close)
                this.show = true
            }
        }
    },
    computed: {
        state_identifier () {
            return "cheatsheet"
        },
        fields () {
            return ["fieldset::cheatsheet"]
        }
    },
    data () {
        return {
            entity: "song",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "title",
                sort_dir: "asc",
                filter_on: "starting_key",
                filter: "*",
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            show: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.popover.popover-bottom.cheatsheet {
    & > .popover-container {
        background: $bg-color-light;
        padding: $layout-spacing-sm;

        .table {
            td,
            th {
                padding: $unit-1 $unit-2;
            }
        }
    }
    &.active {
        & > .popover-container {
            transform: translate(-100%, 0);
        }
    }
}
</style>
